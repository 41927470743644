import { LAYOUT_TYPE_BASIC, LAYOUT_TYPE_BLANK } from 'constants/theme.constant'
import React, { memo, useMemo, lazy, Suspense, useEffect } from 'react'
import { useSelector } from 'react-redux'



const layouts = {
  [LAYOUT_TYPE_BASIC]: lazy(() => import('./BasicLayout')),
  [LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
}

const Index = props => {
  const layoutType = useSelector((state) => state.theme.layout.type)

  const AppLayout = useMemo(() => {
    return layouts[layoutType]
  }, [layoutType])


  return (
    <Suspense
      fallback={
        <div className="flex flex-auto flex-col h-[100vh]">
          <p>Loading ...</p>
        </div>
      }
    >
      <AppLayout />
    </Suspense>
  )
}

export default Index